import Barba from 'barba.js'
import lozad from 'lozad'

const html = document.documentElement
const body = document.querySelector('body')

const CommonView = Barba.BaseView.extend({
  namespace: 'common',
  onEnter: function () {
    // Give a placeholder to images with .lozad class
    const lozadImages = document.querySelectorAll('.lozad')
    lozadImages.forEach(function (img) {
      img.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII='
    })

    // Initialize Lozad
    const observer = lozad()
    observer.observe()

    // Go back to the top of the page
    window.scrollTo({
      top: 0
    })
  },

  onEnterCompleted: function () {
    const openMenu = document.querySelector('.header-menu-icon.open')
    const closeMenu = document.querySelector('.header-menu-icon.close')
    const menuIcons = [openMenu, closeMenu]

    // Menu disappears when resizing the page
    const mq = window.matchMedia('(min-width: 576px)')
    if (mq) {
      mq.addListener(hideMenu)
      // Using hideMenu function defined below
      hideMenu(mq)
    }

    // This function hide mobile menu when resizing page
    function hideMenu (mq) {
      if (mq.matches) {
        // window width is at least 992px
        body.classList.remove('menu-active')
        closeMenu.classList.add('is-hidden')
      } else {
        // window width is less than 992px
        openMenu.classList.remove('is-hidden')
      }
    }

    // Hide or reveal menu overlay with proper icons
    menuIcons.forEach(function (icon) {
      icon.addEventListener('click', () => {
        body.classList.toggle('menu-active')
        menuIcons.forEach(function (item) {
          item.classList.toggle('is-hidden')
        })
      })
    })

    // Display newsletter overlay
    const newsletterTriggers = document.querySelectorAll('.newsletter-overlay-trigger')
    const newsletterOverlay = document.querySelector('.nl-overlay')
    const newsletterCloseButton = document.querySelector('.nl-overlay__close-button')

    if (newsletterTriggers) {
      newsletterTriggers.forEach((btn) => {
        btn.addEventListener('click', function () {
          html.classList.add('overflow-hidden')
          newsletterOverlay.classList.add('is-visible')
        })
      })

      // When close button is clicked
      newsletterCloseButton.addEventListener('click', function () {
        hideOverlay()
      })

      // When ESC key is pressed
      document.onkeydown = function (evt) {
        evt = evt || window.event
        if (evt.keyCode === 27) {
          hideOverlay()
        }
      }
    }

    // Hide overlay on close
    function hideOverlay () {
      html.classList.remove('overflow-hidden')
      newsletterOverlay.classList.remove('is-visible')
    }

    const isMobile = document.querySelector('html').classList.contains('mobile') || document.querySelector('html').classList.contains('tablet')
    const grid = document.querySelector('.grid-artists')
    const artists = document.querySelectorAll('.grid-artist')

    if (isMobile) {
      // Disable links on first click on mobile
      if (artists) {
        artists.forEach(function (artist) {
          const artistLink = artist.querySelector('.grid-artist-link')
          artistLink.classList.add('disabled')
        })
      }

      // Handle mobile click for Grid Artists
      if (grid) {
        document.addEventListener('click', handleArtistClick)
      }
    }
  },

  onLeave: function () {
    // A new Transition toward a new page has just started.
    body.classList.remove('menu-active')

    // Remove event listener for Grid Artists
    const grid = document.querySelector('.grid-artists')

    if (grid) document.removeEventListener('click', handleArtistClick)
  },

  onLeaveCompleted: function () {
    // The Container has just been removed from the DOM.
  }
})

function handleArtistClick (e) {
  if (e.target.classList.contains('grid-artist-content')) {
    const artist = e.target.parentElement
    const artistLink = artist.querySelector('.grid-artist-link')

    // Add 'is active' class to the clicked artist
    if (!artist.classList.contains('is-active')) {
      artist.classList.add('is-active')
    }

    // Remove disabled class from artist link
    artistLink.classList.remove('disabled')
  }

  // Remove 'is active' class from all artists when clicked outside of them
  const artists = document.querySelectorAll('.grid-artist')

  artists.forEach(function (artist) {
    const isClickInside = artist.contains(e.target)

    if (!isClickInside) {
      artist.classList.remove('is-active')

      // Add disabled class again from artist link
      const artistLink = artist.querySelector('.grid-artist-link')
      artistLink.classList.add('disabled')
    }
  })
}

export default CommonView
